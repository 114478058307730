<template>
  <div class="text-center">
    <div class="pt-8 pb-6 mb-8 bg-jooycar-gray-stepperBg">
      <img
        class="pt-8 mx-auto mb-4"
        :src="require('../assets/images/icons/success-image.svg')"
        alt="success-image"
      >
      <div class="text-3xl font-bold text-jooycar-blue-dark">
        Felicitaciones
      </div>
      <div class="font-semibold">
        Has finalizado el proceso con éxito
      </div>
    </div>
    <div class="mb-8">
      <div class="text-lg font-semibold bg-white text-jooycar-blue-dark">
        Próximos pasos:
      </div>
    </div>
    <div class="flex flex-row mx-auto mb-16 md:w-1/2">
      <div class="flex flex-col items-center flex-1 mx-4">
        <div class="h-16 mb-2">
          <span class="inline-block h-full align-middle" />
          <inline-svg
            class="inline-block align-middle"
            :src="require('../assets/images/icons/mail-sending.svg')"
            alt="email"
          />
        </div>
        <div class="">
          Recibirás un correo de confirmación de propuesta
        </div>
      </div>
      <div class="flex flex-col items-center flex-1 mx-4">
        <div class="h-16 mb-2">
          <span class="inline-block h-full align-middle" />
          <inline-svg
            style="width: 66px"
            class="inline-block align-middle"
            :src="require('../assets/images/icons/success-phone.svg')"
            alt="phone"
          />
        </div>
        <div class="">
          Serás contactado a la brevedad para coordinar la inspección de tu vehículo
        </div>
      </div>
    </div>
    <button
      class="block px-10 py-2 mx-auto mb-32 text-sm font-semibold border border-jooycar-gray-lighter text-jooycar-blue"
      @click="newQuotation"
      v-if="!this.$store.state.lead.metadata.executiveId"
    >
      COTIZAR OTRO VEHÍCULO
    </button>
  </div>
</template>

<script>

export default {
  mounted() {
    if (this.$store.state.lead.currentStage == "success") {
      this.$analytics.logEvent(
        this.$analyticsEvents.standard.purchase.eventName,
        { affiliation: this.$analyticsEvents.standard.purchase.params.affiliation }
      );
    } else {
      this.$router.push({ name: this.$store.state.lead.currentStage });
    }
  },
  methods: {
    newQuotation() {
      const { channel } = this.$store.state.lead;
      this.$store.commit('resetState');
      this.$router.push({ name: 'L1', query: { channel } });
    },
    isSWFLead() {
      return this.$store.state.lead.hasOwnProperty("metadata") && this.$store.state.lead.metadata.hasOwnProperty("executiveId");
    }
  },
};
</script>

<style lang="scss" scoped>
.separator {
    @apply flex;
    @apply items-center;
    @apply mx-10;

  &::before, &::after {
    content: '';
    @apply flex-1;
    @apply border-b-2;
    @apply border-solid;
    @apply border-jooycar-gray;
  }

  &::before {
    @apply mr-4;
  }

  &::after {
    @apply ml-4;
  }
}
</style>
